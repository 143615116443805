


import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';

@Component({
  name: 'unsubscribe',
})

export default class Unsubscribe extends Vue {

  public eventId: number = null;
  public contactId: number = null;
  public emailString: string = '';
  public hashString: string = '';
  public success: boolean = false
  public error: Error = null;

  public allowEventSummary: boolean = false;
  public allowNewMeetingRequest: boolean = false;
  public allowMeetingReminder: boolean = false;
  public allowMeetingConfirm: boolean = false;
  public allowNewMessage: boolean = false;

  public mounted(): void {
    this.eventId = parseInt(this.$route.params.eventId, 10);
    this.contactId = parseInt(this.$route.params.contactId, 10);
    this.emailString = this.$route.params.emailString;
    this.hashString = this.$route.params.hashString;
  }

  public setAllowEvenSummary(): void {
    this.allowEventSummary = !this.allowEventSummary;
  }

  public setAllowNewMeetingRequest(): void {
    this.allowNewMeetingRequest = !this.allowNewMeetingRequest;
  }

  public setAllowMeetingReminder(): void {
    this.allowMeetingReminder = !this.allowMeetingReminder;
  }

  public setAllowMeetingConfirm(): void {
    this.allowMeetingConfirm = !this.allowMeetingConfirm;
  }

  public setAllowNewMessage(): void {
    this.allowNewMessage = !this.allowNewMessage;
  }

  public async sendUnsubscribeRequest(): Promise<void> {
    const params = {
      event_id: this.eventId,
      contact_id: this.contactId,
      email: this.emailString,
      hash: this.hashString,
      allow_event_summary: !this.allowEventSummary,
      allow_new_meeting_request: !this.allowNewMeetingRequest,
      allow_meeting_reminder: !this.allowMeetingReminder,
      allow_meeting_confirm: !this.allowMeetingConfirm,
      allow_new_message: !this.allowNewMessage,
    };

    const subscribeRequest = await this.$store.dispatch('mailSettingsStore/unsubscribeRequest', params);

    if (subscribeRequest.status && subscribeRequest.status === 202) {
      this.success = true;
    }

    if (subscribeRequest.error) {
      this.success = false;
      this.error = subscribeRequest.error;
    }
  }
}

